<template>
  <csv-file-uploader class="new-schedule-upload" :template-link="templateLink" @drop="handleDrop">
    <template #content>
      <div>{{ I18n.t('schedule_upload.new.form.title') }}</div>
      <div class="space-y-2 my-2">
        <p class="text-base">{{ I18n.t('schedule_upload.new.form.description_1') }}</p>
        <p class="text-base">{{ I18n.t('schedule_upload.new.form.description_2') }}</p>
        <p class="text-base">{{ I18n.t('schedule_upload.new.form.description_3') }}</p>
      </div>
      <table class="table table-bordered text-sm">
        <thead>
        <tr>
          <th>{{ I18n.t('schedule_upload.new.form.field_name') }}</th>
          <th>{{ I18n.t('schedule_upload.new.form.field_required') }}</th>
          <th>{{ I18n.t('schedule_upload.new.form.field_notes') }}</th>
          <th>{{ I18n.t('schedule_upload.new.form.field_example') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{ headers['date'] }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.date_required') }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.date_notes') }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.date_example') }}</td>
        </tr>
        <tr>
          <td>{{ headers['start_time'] }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.start_time_required') }}</td>
          <td data-testid="timeFormat">{{ I18n.t('schedule_upload.new.form.start_time_notes', { format: timeFormat })
            }}
          </td>
          <td data-testid="timeExample">
            {{ I18n.t(`schedule_upload.new.form.start_time_example_${use12hr ? '12' : '24'}hr`) }}
          </td>
        </tr>
        <tr>
          <td>{{ headers['end_time'] }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.end_time_required') }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.end_time_notes', { format: timeFormat }) }}</td>
          <td>{{ I18n.t(`schedule_upload.new.form.end_time_example_${use12hr ? '12' : '24'}hr`) }}</td>
        </tr>
        <tr>
          <td>{{ headers['punched_in_at'] }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.punched_in_at_required') }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.punched_in_at_notes', { format: timeFormat })
            }}
          </td>
          <td>
            {{ I18n.t(`schedule_upload.new.form.punched_in_at_example_${use12hr ? '12' : '24'}hr`) }}
          </td>
        </tr>
        <tr>
          <td>{{ headers['punched_out_at'] }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.punched_out_at_required') }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.punched_out_at_notes', { format: timeFormat }) }}</td>
          <td>{{ I18n.t(`schedule_upload.new.form.punched_out_at_example_${use12hr ? '12' : '24'}hr`) }}</td>
        </tr>
        <tr>
          <td>{{ headers['shift_template'] }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.shift_template_required') }}</td>
          <td></td>
          <td>{{ I18n.t('schedule_upload.new.form.shift_template_example') }}</td>
        </tr>
        <tr>
          <td>{{ headers['email'] }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.email_required') }}</td>
          <td></td>
          <td>{{ I18n.t('schedule_upload.new.form.email_example') }}</td>
        </tr>
        <tr>
          <td>{{ headers['employee_id'] }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.employee_id_required') }}</td>
          <td></td>
          <td>{{ I18n.t('schedule_upload.new.form.employee_id_example') }}</td>
        </tr>
        <tr>
          <td>{{ headers['department'] }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.department_required') }}</td>
          <td></td>
          <td>{{ I18n.t('schedule_upload.new.form.department_example') }}</td>
        </tr>
        <tr>
          <td>{{ headers['location'] }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.location_required') }}</td>
          <td></td>
          <td>{{ I18n.t('schedule_upload.new.form.location_example') }}</td>
        </tr>
        <tr>
          <td>{{ headers['position'] }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.position_required') }}</td>
          <td></td>
          <td>{{ I18n.t('schedule_upload.new.form.position_example') }}</td>
        </tr>
        <tr>
          <td>{{ headers['job_site'] }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.job_site_required') }}</td>
          <td></td>
          <td>{{ I18n.t('schedule_upload.new.form.job_site_example') }}</td>
        </tr>
        <tr>
          <td>{{ headers['pay_code'] }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.pay_code_required') }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.pay_code_notes') }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.pay_code_example') }}</td>
        </tr>
        <tr>
          <td>{{ headers['notes'] }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.notes_required') }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.notes_notes') }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.notes_example') }}</td>
        </tr>
        <tr>
          <td>{{ headers['breaks'] }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.breaks_required') }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.breaks_notes') }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.breaks_example') }}</td>
        </tr>
        <tr>
          <td>{{ headers['hide_end_time'] }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.hide_end_time_required') }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.hide_end_time_notes', { true_value: 'True', false_value: 'False' })
            }}
          </td>
          <td>{{ `False` }}</td>
        </tr>
        <tr>
          <td>{{ headers['validate_fatigue'] }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.validate_fatigue_required') }}</td>
          <td>{{ I18n.t('schedule_upload.new.form.validate_fatigue_notes', { true_value: 'True', false_value: 'False' })
            }}
          </td>
          <td>{{ `True` }}</td>
        </tr>
        </tbody>
      </table>
    </template>
    <template #file-uploader>
      <csv-file-upload-form
        ref="fileUploader"
        :error-message.sync="errorMessage"
        :processing.sync="processing"
        @submit="handleSubmit">
        {{ I18n.t('user_imports.new.file_upload_modal') }}
      </csv-file-upload-form>
    </template>
  </csv-file-uploader>
</template>

<script>
  import CsvFileUploader from '../uploaders/csv_file_uploader'
  import CsvFileUploadForm from '../uploaders/csv_file_upload_form.vue'

  export default {
    name: 'NewScheduleUpload',
    props: {
      templateLink: String,
      use12hr: Boolean,
      pusherKey: String,
      pusherChannelName: String,
      pusherErrorEvent: String,
      pusherProcessingEvent: String,
      headers: Object
    },
    components: {
      CsvFileUploadForm,
      CsvFileUploader
    },
    data() {
      return {
        errorMessage: '',
        processing: false
      }
    },
    computed: {
      timeFormat() {
        let format = 'HH:MM'
        if (this.use12hr) {
          format += ' AM/PM'
        }
        return format
      }
    },
    mounted() {
      const pusher = window.getPusherInstance(this.pusherKey)
      const pusherChannel = pusher.subscribe(this.pusherChannelName)
      this.subscribeToChannelEvents(pusherChannel)
    },
    methods: {
      subscribeToChannelEvents(channel) {
        channel.bind(this.pusherErrorEvent, (data) => {
          this.processing = false
          this.errorMessage = data.error
        })
        channel.bind(this.pusherProcessingEvent, (data) => {
          setTimeout(() => window.location.href = `/schedule_uploads/${data.schedule_upload_id}`, 1000)
        })
      },
      handleDrop(event) {
        const { files } = event.dataTransfer
        if (files.length > 1) {
          $('.error-status').html(I18n.t('user_imports.errors.multiple_files'))
        } else {
          this.$refs.fileUploader.handleFileSubmit(files[0])
        }
      },
      handleSubmit(file) {
        const data = new FormData()
        data.append('schedule_upload[file]', file, file.name)
        const vm = this

        $.ajax({
          url: '/schedule_uploads',
          method: 'POST',
          data,
          contentType: false,
          processData: false,
          error: function(response) {
            const message = response?.responseJSON?.errors || 'An error occurred. Please try again.'
            vm.processing = false
            vm.errorMessage = message
          }
        })
      }
    }
  }
</script>
