
window.AdminSmartinsightsUsers = (function($, window, document) {
  var init = function () {
    initActivateButtons();
  };

  function initActivateButtons() {
    $("body").on("click", ".user-activate-button", function(e) {
      var button = $(this);
      var userId = button.data('user-id');
      var basePath = window.location.pathname;

      if (button.data("state") == "deactivate") {
        return deactivateUser(userId, basePath);
      }

      if (button.data("state") == "activate") {
        return activateUser(userId, basePath);
      }
    });
  }

  function activateUser(userId, basePath) {
    var button = $('.user-activate-button[data-user-id="' + userId + '"]');
    var editButton = $('.user-edit-button[data-user-id="' + userId + '"]');

    setButtonLoading(button);

    $.ajax({
      method: "POST",
      dataType: "json",
      url: `${basePath}/${userId}/activate`,
      success: function(data) {
        setButtonDeactivate(button, editButton);
      },
      error: function(xhr, status, error) {
        alert("An error occurred while activating the user: " + (xhr.responseJSON?.message || error));
        setButtonActivate(button, editButton);
      }
    });
  }

  function deactivateUser(userId, basePath) {
    var button = $('.user-activate-button[data-user-id="' + userId + '"]');
    var editButton = $('.user-edit-button[data-user-id="' + userId + '"]');

    $.ajax({
      method: "POST",
      dataType: "json",
      url: `${basePath}/${userId}/deactivate`,
      success: function(data) {
        setButtonActivate(button, editButton);
      },
      error: function(xhr, status, error) {
        alert("An error occurred while deactivating the user: " + (xhr.responseJSON?.message || error));
      }
    });
  }

  var setButtonLoading = function(button) {
    button.removeClass("btn-gray btn-danger").addClass("btn-gray");
    button.data("state", "loading");
    button.text('Loading');
  };

  var setButtonActivate = function(button, editButton) {
    button.removeClass("btn-gray btn-danger").addClass("btn-success");
    button.data("state", "activate");
    button.text('Activate');
    hideEditButton(editButton);
  };

  var setButtonDeactivate = function(button, editButton) {
    button.removeClass("btn-gray btn-success").addClass("btn-danger");
    button.data("state", "deactivate");
    button.text('Deactivate');
    showEditButton(editButton);
  };

  var hideEditButton = function(button) {
    button.addClass("hide");
  };

  var showEditButton = function(button) {
    button.removeClass("hide");
  };

  return {
    init: init
  };

})(jQuery, window, document);
